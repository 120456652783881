import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {
    MDBContainer,
    MDBBadge,
    MDBTypography,
    MDBIcon,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBBtn,
    MDBModalBody,
    MDBModal,
} from "mdb-react-ui-kit";
import {Link, useNavigate, useParams} from "react-router-dom";
import bindActionCreators from "react-redux/es/utils/bindActionCreators";
import {setUser} from "../../../../Context/reducers/User/actions";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {QueryClient, QueryClientProvider, useInfiniteQuery} from "@tanstack/react-query";
import MaterialReactTable from "material-react-table";
import {Button, MenuItem, Typography} from "@mui/material";
import eventBus from "../../../../Context/EventBus";

const GET_DRUGS = gql`
  query Get( $skip: Int!, $take: Int!, $entityId: Long!) {
    classDrugs(entityId: $entityId skip: $skip, take: $take) {
      totalCount
      items
      {
        id
        name
        ndc
        ldd_classification_id
        ldd_classification{
            value
        }
        clearwayDrug {
                ndc
                classification_id
                is_orphaned
                classification {
                 value
                }
            }
         drug {
            ndc
            gpi
            name
            strength
            strength_unit_of_measure
            dosage_form
            
           }
      }
      
    }
  }
`;

const GET_LOOKUP = gql`
 query Get($id: String!) {
     groupLookupValues(groupName: $id) {
        id
        value
    }
 }
`;

const SET_DRUG_LDD = gql`
mutation set($entityId: Long!, $id: Long!, $lddId: Long! )
{
    setEntityDrugClassLDD(entityId: $entityId, id: $id, ldd_classificatino_id:$lddId) {
        successful
    }
}
`;

const SET_DRUG_NDC = gql`
mutation set($entityId: Long!, $id: Long!, $ndc: String!)
{
    setEntityDrugClassNdc(ndc: $ndc, entityId: $entityId, id: $id) {
        successful
    }
}
`;

const CLASSING_CLEANUP = gql`
mutation clean( $entityId: Long!)
{
    cleanDrugClass(entityId: $entityId)
  {
      successful
  }
} 
`;

const DrugTable = ({user}) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    //const virtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
    const params = useParams();
    const fetchSize = 50;

    const [totalRows, setTotalRows] = useState(0);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);
    const [getDrugs,{error, loading}] = useLazyQuery(GET_DRUGS);
    const [getLookup,{error: getLookupError, loading: getLookupLoading}] = useLazyQuery(GET_LOOKUP);
    const [drugClassing, setDrugClassing] = useState([]);
    const [TableData, setTableData] = useState([]);
    const [setNDC] = useMutation(SET_DRUG_NDC);
    const [setDrugLdd] = useMutation(SET_DRUG_LDD);
    const [modalCleanup, setModalCleanup] = useState(false);

    const [cleanupClassing] = useMutation(CLASSING_CLEANUP,{
        variables: {
            entityId: Number(params.entity_id)
        },
        context:{
            headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
        }
    })

    useEffect(()=> {
        getLookup({
            variables: {
                id: 'drug_ldd_classing',

            },
            context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
            .then( (result) => {

                let data = result.data.groupLookupValues.map( (row) => {
                    return {text:row.value, value:row.id}
                })

                setDrugClassing(data)
            })
    }, [user]);

    const columns = [
        // {
        //     accessorKey: 'id',
        //     header: 'ID',
        //     size: 100,
        //     Cell: ({ cell }) => (
        //         <Link to={'/admin/entity/'+params.entity_id+'/class/drug/'+cell.getValue()}>{cell?.getValue()}</Link>
        //     ),
        // },
        {
            accessorKey: 'name',
            header: 'Drug Name',
            enableEditing: false,
            size: 500,
        },

        {
            accessorKey: 'ndc',
            header: 'NDC',
            // This won't work in table edit mode
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}}/> {cell.getValue() } </span>),
        },
        {
            accessorKey: 'ldd_classification.value',
            header: 'LDD',
            // This won't work in table edit mode
            Cell: ({cell}) => (<span><MDBIcon far icon="edit" style={{cursor:"pointer"}}/>  <MDBBadge pill >{cell?.getValue()}</MDBBadge> </span>),

            muiTableBodyCellEditTextFieldProps: {
                select: true, //change to select for a dropdown
                children: drugClassing.map((row, id) => (
                    <MenuItem key={id} value={row.text}>
                        {row.text}
                    </MenuItem>
                )),
            },

        },
        {
            accessorKey: 'drug.gpi',
            header: 'GPI',
            enableEditing: false,
        },
        {
            accessorKey: 'clearwayDrug.is_orphaned',
            header: 'Orphan',
            // This won't work in table edit mode
            Cell: ({ cell }) => (
                <span className="text-center">{cell?.getValue() ? <> <MDBBadge pill >Orphan</MDBBadge></>:""}</span>
            ),
            enableEditing: false,
        },
        {
            accessorKey: 'clearwayDrug.classification.value',
            header: 'Classification',
            enableEditing: false,
        },
    ];

    /* Legacy Infinite Scroll Implementation
    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', columnFilters, globalFilter, sorting],
            queryFn: async ({ pageParam = 0 }) => {

                let dd = await getDrugs({
                    variables: {
                        skip: pageParam * fetchSize,
                        take: fetchSize,
                        entityId: Number(params.entity_id)
                    },
                    context:{
                        headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}
                    }
                }).then()

                setTotalRows(dd.data.classDrugs.totalCount ?? 0)
                setTableData(TableData =>Array.from(new Set([...TableData, ...dd.data.classDrugs.items])));

                return [];

            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: false,
            refetchOnWindowFocus: false,

        });

    const totalDBRowCount = totalRows ?? 0;
    const totalFetched = TableData.length;

    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        async (containerRefElement) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (scrollHeight - scrollTop - clientHeight < 400 && !isFetching && totalFetched < totalDBRowCount){await fetchNextPage().then();}
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {if (virtualizerInstanceRef.current) {virtualizerInstanceRef.current.scrollToIndex(0);}}, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {fetchMoreOnBottomReached(tableContainerRef.current).then();}, [fetchMoreOnBottomReached]);

    */

    const fetchAllData = async () => {
      let allData = [];
      let page = 0;
      let batchSize = 50;  // Use the backend's limit
  
      while (true) {
          const { data } = await getDrugs({
              variables: {
                  skip: page * batchSize,
                  take: batchSize,
                  entityId: Number(params.entity_id),
                  search: globalFilter ?? ''
              },
              context: {
                  headers: { "Authorization": user.isAuthenticated ? 'Bearer ' + user.token : '' }
              }
          });
  
          if (data.classDrugs.items.length === 0) break;
  
          allData = [...allData, ...data.classDrugs.items];
          if (allData.length >= data.classDrugs.totalCount) break;
  
          page += 1;
      }
      setTableData(allData);
      setTotalRows(allData.length);
  };
  
  useEffect(() => {
      fetchAllData();
  }, [globalFilter, columnFilters, sorting]);

    function updateNDC(row, type, ndc) {
      setNDC({
          variables: {
              ndc: ndc,
              id:  Number(row.original.id),
              entityId: Number(params.entity_id)
          },
          context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
          .then( (result) => {
              NotificationManager.success("Classing Updated!");
              let values = row.original;
              values.ndc =ndc;
              eventBus.dispatch("drug.class.update_row", values);
          })
          .catch( (error) => {
              NotificationManager.error("Api Error");
          })
    }

    function updateLddClassification(row, type, ldd) {
      setDrugLdd({
          variables: {
              lddId: ldd.value,
              id:  Number(row.original.id),
              entityId: Number(params.entity_id)
          },
          context:{headers:{"Authorization":user.isAuthenticated ? 'Bearer ' +user.token : ''}}})
          .then( (result) => {
              NotificationManager.success("LDD Access Updated!");
              let values = row.original;
              values.ldd_classification.value =ldd.text;
              eventBus.dispatch("drug.class.update_row", values);
          })
          .catch( (error) => {
              NotificationManager.error("Api Error");
          });
    }

    eventBus.on("drug.class.update_row", (row) =>{
        let index = TableData.findIndex((line) => line.id === row.id);
        var tempTableData = [...TableData];
        tempTableData[index] = row;
        setTableData(tempTableData);
    });

    function confirmClassCleanup()
    {
        setModalCleanup(false);
        cleanupClassing().then(r => {
            NotificationManager.success("Classing Cleanup Submitted!");
        }).catch((error) =>{
            NotificationManager.error("Api Error");
        })

    }

    return (
        <div >
            <MaterialReactTable
                columns={columns}
                editingMode="table"
                enableEditing
                muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
                    //onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                        // console.log(cell, event.target.value);
                        // console.log(row); //original

                        var newValue = event.target.value;
                        console.log(cell.column.id, newValue)

                        if(cell.column.id === "ndc") {
                          const currentNDC = row.original.ndc;
                          // Check if the new value is different from the current value
                          if (currentNDC !== newValue) {
                              updateNDC(row, event.target.value, newValue);
                          }
                        }

                        if (cell.column.id === "ldd_classification.value") {
                          let ldd = drugClassing.find((element) => element.text === newValue);
                          const currentLddText = row.original.ldd_classification.value;
          
                          // Mapping between string values and numeric values
                          const lddMapping = {
                              "Access": 26,
                              "No Access": 27
                          };
          
                          const currentLddValue = lddMapping[currentLddText];
          
                          // Check if the new value is different from the current value
                          if (currentLddValue !== ldd.value) {
                              updateLddClassification(row, event.target.value, ldd);
                          }
                      }
                    },
                })}

                data={TableData}
                enableColumnFilters={true}
                //enablePagination={false}
                enableSorting={true}
                enableGlobalFilter={true}
                enableFilters={true}

                //enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                /* Legacy Infinite Scroll Implementation
                muiTableContainerProps={{
                    ref: tableContainerRef, //get access to the table container element
                    sx: { maxHeight: '800px' }, //give the table a max height
                    onScroll: (
                        event, //add an event listener to the table container element
                    ) => fetchMoreOnBottomReached(event.target),
                }}
                */
                muiToolbarAlertBannerProps={error ? { color: 'error',  children: 'Error loading data',} : undefined}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}

                /* Legacy Total Row Count Implementation
                renderBottomToolbarCustomActions={() => (
                    <Typography>
                        {TableData.length.toLocaleString("en-US")} of {totalRows.toLocaleString("en-US")} total rows.
                    </Typography>
                )}
                */

                renderTopToolbarCustomActions={() => <Typography variant="h5" className="m-3">Drug Classing <span><Button color={"secondary"} size={"small"} onClick={()=> setModalCleanup(true)}>Cleanup Classing</Button></span></Typography>}
                enableDensityToggle={false}
                initialState={{
                  pagination: {
                      pageIndex: 0,
                      pageSize: 10,
                    },
                  density: 'compact'
                }}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading: loading,
                    showAlertBanner: error,
                    showProgressBars: loading,
                    sorting,
                }}
                rowVirtualizerProps={{ overscan: 4 }}
            />

            <MDBModal open={modalCleanup} tabIndex='-1' onClose={()=>setModalCleanup(false)}>
                <MDBModalDialog size='lg'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Cleanup Confirmation</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={()=>setModalCleanup(false)}/>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <Typography fontSize={20}>
                                Are you sure you want to clean up the classing records?
                            </Typography>
                            <p>
                                This will go through all the classing records and delete any that are no longer connected to raw data files.
                            </p>

                            <Button onClick={()=>confirmClassCleanup()}>Confirm Classing Cleanup</Button>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

        </div>
    );
};


const queryClient = new QueryClient();

export function Page({user}) {
    return (
        <MDBContainer >
            <QueryClientProvider client={queryClient}>
                <DrugTable user={user} />
            </QueryClientProvider>
        </MDBContainer>
    );
}

const mapStateToProps = state => {

    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({setUser}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Page)
